// Importar todos los plugins
import './custom.scss';
import WOW from '../node_modules/wow.js/';
import * as bootstrap from 'bootstrap';

new WOW().init(
    {
        animateClass: 'animate__animated',
        mobile: true
    }
);


console.log("WOW initialized");

// const navLinks = document.querySelectorAll('.nav-item')
// const menuToggle = document.getElementById('navbarSupportedContent')
// const bsCollapse = new bootstrap.Collapse(menuToggle, {toggle:false})
// navLinks.forEach((l) => {
//     l.addEventListener('click', () => { bsCollapse.toggle() })
// })